.Caption {
  background: #0071bc;
  width: 130px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  font-family: "Lato", "Open Sans", sans-serif;
  font-weight: 300;
}

.GridContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.GridElement {
  cursor: pointer;
  position: relative;
  text-align: center;
  font-family: "Lato", "Open Sans", sans-serif;
  color: white;
  font-size: 2em;
  font-weight: 300;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.Title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Lato", "Open Sans", sans-serif;
  color: #0071bc;
  font-size: 2em;
  font-weight: 300;
}

.BaseColor1 {
  background: #0071bc;
}

.BaseColor1:hover {
  background: #0061a1;
}

.BaseColor2 {
  background: #b35340;
}

.BaseColor2:hover {
  background: #924334;
}

.BaseColor3 {
  background: #1bab7f;
}

.BaseColor3:hover {
  background: #188e6a;
}

.BaseColor4 {
  background: #c2a33a;
}

.BaseColor4:hover {
  background: #ae9336;
}

.BaseColor5 {
  background: #3f5d6e;
}

.BaseColor5:hover {
  background: #314d5c;
}

.BaseColor6 {
  background: #3f5d6e;
}

.GridElement p {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0;
  margin: 0;
  transform: translate(-50%, -50%);
}

.GridContainer a {
  position: relative;
  display: block;
  width: 70%;
  height: 130px;
  margin: 30px;
}

.formLnk {
  background: lightcoral;
  width: 100%;
  height: 100%;
  cursor: default;
}

.formBtn {
  margin: 0 auto;
  margin-top: 50px;
  background: #1bab7f;
  width: 210px;
  height: 50px;
  cursor: pointer;
  position: relative;
  text-align: center;
  font-family: "Lato", "Open Sans", sans-serif;
  color: white;
  font-size: 1.5em;
  font-weight: 300;
  border-radius: 25px;
}

.formBtn:hover {
  background: #188e6a;
}

.formBtn p {
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  padding: 0;
  transform: translate(-50%, -50%);
}

h1 {
  font-family: "Lato", "Open Sans", sans-serif;
  color: #0071bc;
  font-size: 1.5em;
  font-weight: 300;
  margin-top: 50px;
}

h2 {
  font-family: "Lato", "Open Sans", sans-serif;
  color: #0071bc;
  font-size: 1.5em;
  font-weight: 300;
}

h3 {
  font-family: "Lato", "Open Sans", sans-serif;
  color: #aaaaaa;
  font-size: 1.5em;
  font-weight: 300;
}

a {
  text-decoration: none;
  color: black;
}

@media (min-width: 600px) {
  .GridContainer a {
    height: 200px;
    max-width: 400px;
  }
}
