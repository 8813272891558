html {
  height: 100%;
}

#root {
  height: 100%;
}

body {
  background: #f5f5f5;
  margin: 0;
  padding: 0;
  height: 100%;
}
